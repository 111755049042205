<template>
  <v-container class="py-7">
    <div class="heading mb-7">Адреса доставки</div>

    <template v-if="addressBook && addressBook.length">
      <v-sheet
        v-for="(item, i) in addressBook"
        :key="i"
        class="custom-field"
        v-ripple="{ class: 'secondary--text' }"
        @click="select(item)"
      >
        <span>{{ item.location_name }}</span>
        <v-icon color="secondary" size="32" class="mr-n2 ml-auto">{{
          icons.mdiDotsVertical
        }}</v-icon>
      </v-sheet>
    </template>

    <div v-else>У вас нет сохраненных адресов.</div>

    <v-dialog v-model="dialog" max-width="320">
      <v-card v-if="selected">
        <v-card-title class="dialog-title justify-center text-center py-8">
          <span> Выберите действие с адресом</span>
          <b>{{ selected.location_name }}</b>
        </v-card-title>
        <v-card-actions class="justify-center pa-0">
          <v-btn
            color="primary"
            class="grow ma-0"
            tile
            depressed
            x-large
            @click="update"
            >Редактировать</v-btn
          >
          <v-btn
            color="secondary"
            class="grow ma-0"
            tile
            depressed
            x-large
            @click="deleteAddress"
            >Удалить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { mdiDotsVertical } from '@mdi/js'
import actionButtonMixin from '@/mixins/actionButton'

export default {
  name: 'Addresses',

  mixins: [actionButtonMixin],

  data: () => ({
    icons: {
      mdiDotsVertical,
    },
    dialog: false,
    selected: null,
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        action: () =>
          this.$router.push({ name: 'AddressDetail', params: { id: 0 } }),
        text: 'Добавить адрес',
      }
    },

    ...mapState('user', ['addressBook']),
  },

  methods: {
    select(address) {
      this.selected = address
      this.dialog = true
    },

    update() {
      this.$router.push({
        name: 'AddressDetail',
        params: { id: this.selected.id },
      })
    },

    async deleteAddress() {
      const { commit, dispatch } = this.$store
      const response = await dispatch('user/deleteAddress', this.selected.id)
      if (response.code == 1) {
        const text = 'Адрес удален'
        commit('snackbar/update', { active: true, text })
      }
      this.loadAddresses()
      this.dialog = false
    },

    async loadAddresses() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('user/loadAddressBook')
      commit('overlay/update', { active: false })
    },
  },

  created() {
    this.loadAddresses()
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  display: flex;
  gap: 5px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px !important;
  font-weight: 600 !important;
}
</style>
